import React, { useContext } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { OptionsContext } from '/src/contexts/OptionsContext'

const HelpCategories = loadable(() => import('/src/components/Help/HelpCategories'))
const HelpCta = loadable(() => import('/src/components/Help/HelpCta'))
const LayoutInner = loadable(() => import('/src/components/Layout/LayoutInner'))
const Search = loadable(() => import('/src/components/Search/Search'))
const Seo = loadable(() => import('/src/components/Structure/Seo'))

const HelpCentreHub = ({ data: { search } }) => {
    const [options] = useContext(OptionsContext)

    const postTypeInfo = {
        postType: options.helpCentre.postTypeName,
        title: options.helpCentre.title,
        uri: options.helpCentre.relativeUrl,
        hero: {
            eyebrow: options.helpCentre.heroEyebrow,
            title: options.helpCentre.heroTitle
        }
    }

    const post = {
        seo: {
            title: `${postTypeInfo.title} - ${options.siteData.title}`
        }
    }

    const breadcrumb = {
        type: 'back',
        backTitle: postTypeInfo.title,
        uri: postTypeInfo.uri,
        pageParent: {
            node: {
                title: postTypeInfo.title,
                uri: postTypeInfo.uri
            }
        }
    }

    return (
        <LayoutInner>
            <Seo post={post} />

            <main>
                <article itemScope itemType="http://schema.org/Article">
                    <Search
                        postTypeInfo={postTypeInfo}
                        breadcrumb={breadcrumb}
                        headingLevel={'h1'}
                        searchData={search}
                    />

                    <HelpCategories backgroundColour={'light'} />

                    <HelpCta backgroundColour={'alt'} />
                </article>
            </main>
        </LayoutInner>
    )
}

HelpCentreHub.propTypes = {
    /**
     * Post object
     */
    data: PropTypes.object.isRequired
}

export default HelpCentreHub

export const pageQuery = graphql`
    query {
        search: localSearchPages {
            index
            store
        }
    }
`
